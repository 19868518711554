import { AppDispatch, AppGetState, RootState } from 'types/RootState.types';

// Config
import { getDefaultPaymentCardOrFirst } from '@redux/reducers/user/userReducer';
import { DETERMINE_SELECTED_PAYMENT_TYPE } from '@constants/actionConstants';
import featureConstants from '@constants/featureConstants';
import paymentTypeConstants from '@constants/paymentTypeConstants';
import userConstants from '@constants/userConstants';
import { isBasketClearpayEligible } from '@utils/payment/isBasketClearpayEligible';
import { isBasketKlarnaEligible } from '@utils/payment/isBasketKlarnaEligible';
import isBasketPayPalPayLaterEligible from '@utils/payment/isBasketPayPalPayLaterEligible';
import isPayPalAvailable from '@utils/payment/isPayPalAvailable';
import { isEmptyObject } from '@utils/object';
import isZeroAmount from '@utils/payment/isZeroAmount';
import {
  isFeatureActive,
  getFeatureVariant,
} from '@redux/reducers/config/configReducer';

//
import { setSelectedPaymentType } from './paymentActions';

export function canSelectGooglePay({
  canMakeGooglePayPayments = false,
  googlePayPaymentsClientHasBeenCreated = false,
}) {
  return canMakeGooglePayPayments && googlePayPaymentsClientHasBeenCreated;
}

export function paymentNoLongerEligible(
  isEligible = false,
  selectedPaymentType = '',
  paymentType = '',
) {
  return !isEligible && selectedPaymentType?.includes(paymentType);
}

export function posCreditNoLongerEligible(
  posCreditEligible = false,
  selectedPaymentType = '',
) {
  return paymentNoLongerEligible(posCreditEligible, selectedPaymentType, paymentTypeConstants.POS_CREDIT);
}

export function payPalPayLaterNoLongerEligible(
  payPalPayLaterEligible = false,
  selectedPaymentType = '',
) {
  return paymentNoLongerEligible(payPalPayLaterEligible, selectedPaymentType, paymentTypeConstants.PAYPAL_PAY_LATER);
}

export function clearpayNoLongerEligible(
  clearpayEligible = false,
  selectedPaymentType = '',
) {
  return paymentNoLongerEligible(clearpayEligible, selectedPaymentType, paymentTypeConstants.CLEARPAY);
}

export function klarnaNoLongerEligible(
  klarnaEligible = false,
  selectedPaymentType = '',
) {
  return paymentNoLongerEligible(klarnaEligible, selectedPaymentType, paymentTypeConstants.KLARNA);
}

export const checkPayLaterOptionsAvailable = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const { selectedPaymentType, posCreditEligible } = getState().payment;

  let selectNewPaymentType = false;

  if (selectedPaymentType?.includes(paymentTypeConstants.CLEARPAY)) {
    const { isEligible: clearpayEligible } = isBasketClearpayEligible(getState());
    if (clearpayNoLongerEligible(clearpayEligible, selectedPaymentType)) {
      selectNewPaymentType = true;
    }
  } else if (selectedPaymentType?.includes(paymentTypeConstants.KLARNA)) {
    const { isEligible: klarnaEligible } = isBasketKlarnaEligible(getState());
    if (klarnaNoLongerEligible(klarnaEligible, selectedPaymentType)) {
      selectNewPaymentType = true;
    }
  } else if (selectedPaymentType?.includes(paymentTypeConstants.PAYPAL_PAY_LATER)) {
    const payPalPayLaterEligible = isBasketPayPalPayLaterEligible(getState());
    if (payPalPayLaterNoLongerEligible(payPalPayLaterEligible, selectedPaymentType)) {
      selectNewPaymentType = true;
    }
  } else if (
    selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT) &&
    posCreditNoLongerEligible(posCreditEligible, selectedPaymentType)
  ) {
    selectNewPaymentType = true;
  }

  if (selectNewPaymentType) {
    return dispatch(setSelectedPaymentType());
  }

  return {};
};

export const priorityOrder = [
  paymentTypeConstants.APPLE_PAY,
  paymentTypeConstants.GOOGLE_PAY,
  paymentTypeConstants.SAVED_PAYMENT_CARD,
  paymentTypeConstants.CREDIT_CARD,
  paymentTypeConstants.PAYPAL,
];

export function getPayNowPriorityOrder(paymentMethodPreference = '') {
  const {
    APPLE_PAY,
    GOOGLE_PAY,
    SAVED_PAYMENT_CARD,
    PAYPAL,
  } = paymentTypeConstants;

  const {
    PAYMENT_PREFERENCE_PAYPAL,
    PAYMENT_PREFERENCE_APPLE_PAY,
    PAYMENT_PREFERENCE_GOOGLE_PAY,
    PAYMENT_PREFERENCE_SAVED_CARD,
  } = userConstants;

  if ([
    PAYMENT_PREFERENCE_PAYPAL,
    PAYMENT_PREFERENCE_APPLE_PAY,
    PAYMENT_PREFERENCE_GOOGLE_PAY,
    PAYMENT_PREFERENCE_SAVED_CARD,
  ].includes(paymentMethodPreference)) {
    const lookup = new Map();
    lookup.set(PAYMENT_PREFERENCE_APPLE_PAY, APPLE_PAY);
    lookup.set(PAYMENT_PREFERENCE_GOOGLE_PAY, GOOGLE_PAY);
    lookup.set(PAYMENT_PREFERENCE_SAVED_CARD, SAVED_PAYMENT_CARD);
    lookup.set(PAYMENT_PREFERENCE_PAYPAL, PAYPAL);

    // move the preferred payment method to the front of the list
    const mappedPreference = lookup.get(paymentMethodPreference);
    const found = priorityOrder.indexOf(mappedPreference);
    priorityOrder.unshift(priorityOrder.splice(found, 1)[0]);
  }

  return priorityOrder;
}

export const willSelectNewCard = (state: RootState) => {
  const {
    payment: {
      applePayCompatibilityChecksComplete = false,
      paymentWallet = [],
      canMakeGooglePayPayments = false,
      googlePayPaymentsClientHasBeenCreated = false,
    } = {},
  } = state ?? {};

  // apple pay will be selected
  if (applePayCompatibilityChecksComplete) return false;

  // google pay will be selected
  if (canSelectGooglePay({ canMakeGooglePayPayments, googlePayPaymentsClientHasBeenCreated})) return false;

  // saved card will be selected
  if (!isEmptyObject(paymentWallet)) return false;

  // new card will be selected
  return true;
};

export const canSelectPaymentMethod = (newSelectedPaymentType: string, state: RootState) => {
  if (newSelectedPaymentType !== paymentTypeConstants.CREDIT_CARD) return true;

  if (!isFeatureActive(state, featureConstants.PRIORITISE_NEW_CARD)) return true;

  if (getFeatureVariant(state, featureConstants.PRIORITISE_NEW_CARD) === 'open') return true;

  return false;
};

export const determineSelectedPaymentType = () => async (dispatch: AppDispatch, getState: AppGetState) => {
  if (isZeroAmount(getState().orderForm?.amounts?.outstandingBalance)) {
    dispatch({
      type: DETERMINE_SELECTED_PAYMENT_TYPE,
      selectedPaymentType: undefined,
    });

    return;
  }

  await dispatch(checkPayLaterOptionsAvailable());

  const {
    applePayCompatibilityChecksComplete,
    selectedPaymentType,
    paymentWallet = [],
    canMakeGooglePayPayments,
    googlePayPaymentsClientHasBeenCreated,
  } = getState().payment;

  if (!selectedPaymentType) {
    let newSelectedPaymentType: string | undefined = undefined;

    const defaultSavedPaymentCard = getDefaultPaymentCardOrFirst(paymentWallet);

    const {
      APPLE_PAY,
      GOOGLE_PAY,
      SAVED_PAYMENT_CARD,
      CREDIT_CARD,
      PAYPAL,
    } = paymentTypeConstants;

    const priorityOrder = getPayNowPriorityOrder(getState().user?.customerPreferences?.paymentChoice);

    priorityOrder.forEach(((method) => {
      // Try and select each payment method in turn, setting newSelectedPaymentType if any succeed to stop trying others
      if (!newSelectedPaymentType) {
        if (method === APPLE_PAY && applePayCompatibilityChecksComplete) newSelectedPaymentType = method;
        if (method === GOOGLE_PAY && canSelectGooglePay({
          canMakeGooglePayPayments,
          googlePayPaymentsClientHasBeenCreated,
        })) newSelectedPaymentType = method;
        if (method === SAVED_PAYMENT_CARD && defaultSavedPaymentCard) newSelectedPaymentType = `${SAVED_PAYMENT_CARD}:${defaultSavedPaymentCard.id}`;
        if (method === CREDIT_CARD && isEmptyObject(paymentWallet)) newSelectedPaymentType = CREDIT_CARD;
        if (method === PAYPAL && isPayPalAvailable(getState())) newSelectedPaymentType = method;
      }
    }));

    if (newSelectedPaymentType) {
      dispatch({
        type: DETERMINE_SELECTED_PAYMENT_TYPE,
        selectedPaymentType: canSelectPaymentMethod(newSelectedPaymentType, getState())
          ? newSelectedPaymentType
          : undefined,
      });
    }
  }
};

export default determineSelectedPaymentType;
