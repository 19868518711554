// Config
import {
  CLEARPAY_MAX_SPEND,
  CLEARPAY_MIN_SPEND,
  KLARNA_MAX_SPEND,
  KLARNA_MIN_SPEND,
} from '@constants/paymentConstants';
import paymentTypeConstants from '@constants/paymentTypeConstants';

export const paymentMethods = {
  [paymentTypeConstants.CLEARPAY]: 'Clearpay',
  [paymentTypeConstants.KLARNA]: 'Klarna',
};

export const PAYMENT_BLOCKERS = {
  BASKET_CONTAINS_ALCOHOL: 'BASKET_CONTAINS_ALCOHOL',
  CLEARABEE_DELIVERIES: 'CLEARABEE_DELIVERIES',
  BALANCE_BELOW_MIN: 'BALANCE_BELOW_MIN',
  BALANCE_ABOVE_MAX: 'BALANCE_ABOVE_MAX',
} as const;

const formatCurrency = (currency = 0) => currency.toString().replace('000', ',000');

export const PAYMENT_BLOCKERS_TEXT_MAP = Object.fromEntries(
  [paymentTypeConstants.CLEARPAY, paymentTypeConstants.KLARNA].map((paymentType) => [
    paymentType,
    {
      [PAYMENT_BLOCKERS.BALANCE_ABOVE_MAX]: `your basket is above £${
        paymentType === paymentTypeConstants.CLEARPAY ?
          formatCurrency(CLEARPAY_MAX_SPEND) :
          formatCurrency(KLARNA_MAX_SPEND)
      }`,
      [PAYMENT_BLOCKERS.BALANCE_BELOW_MIN]: `your basket is below £${
        paymentType === paymentTypeConstants.CLEARPAY ? CLEARPAY_MIN_SPEND : KLARNA_MIN_SPEND
      }`,
      [PAYMENT_BLOCKERS.BASKET_CONTAINS_ALCOHOL]: 'your basket contains alcohol',
      [PAYMENT_BLOCKERS.CLEARABEE_DELIVERIES]: 'your basket contains a Clearabee collection service',
    },
  ]),
) as Record<
  (typeof paymentTypeConstants)[keyof typeof paymentTypeConstants],
  Record<keyof typeof PAYMENT_BLOCKERS, string>
>;

export const getPaymentBlockerMessage = (
  code: keyof typeof PAYMENT_BLOCKERS,
  paymentType: keyof typeof PAYMENT_BLOCKERS_TEXT_MAP,
): string | undefined => {
  return PAYMENT_BLOCKERS_TEXT_MAP[paymentType]?.[code];
};
