import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';

// Config
import { getBasketContainsAlcoholSelector } from './isBasketKlarnaEligible';
import { PAYMENT_BLOCKERS } from '@components/payment-option-unavailable/PaymentOptionUnavailable.config';
import featureConstants from '@constants/featureConstants';
import { CLEARPAY_MAX_SPEND, CLEARPAY_MIN_SPEND } from '@constants/paymentConstants';
import { isFeatureActive } from '@redux/reducers/config/configReducer';
import { getDeliveriesWithClearabee } from '@redux/reducers/delivery/deliverySelector';
import { getOutstandingBalanceRawSelector } from '@redux/reducers/order-form/orderFormSelector';

export type ClearpayEligibilityType = {
  isEligible: boolean;
  reasons: (typeof PAYMENT_BLOCKERS)[keyof typeof PAYMENT_BLOCKERS][];
};

export const shouldShowClearpayFeature = (state: RootState): boolean =>
  !isFeatureActive(state, featureConstants.DISABLE_CLEARPAY);

export const selectClearpayIneligibilityReasons = createSelector(
  [getDeliveriesWithClearabee, getOutstandingBalanceRawSelector, getBasketContainsAlcoholSelector],
  (deliveriesWithClearabee = [], outstandingBalanceRaw, basketContainsAlcohol) => {
    const reasons: (keyof typeof PAYMENT_BLOCKERS)[] = [];

    if (basketContainsAlcohol) {
      reasons.push(PAYMENT_BLOCKERS.BASKET_CONTAINS_ALCOHOL);
    }
    if (deliveriesWithClearabee.length > 0) {
      reasons.push(PAYMENT_BLOCKERS.CLEARABEE_DELIVERIES);
    }
    if (Number(outstandingBalanceRaw) < CLEARPAY_MIN_SPEND) {
      reasons.push(PAYMENT_BLOCKERS.BALANCE_BELOW_MIN);
    }
    if (Number(outstandingBalanceRaw) > CLEARPAY_MAX_SPEND) {
      reasons.push(PAYMENT_BLOCKERS.BALANCE_ABOVE_MAX);
    }

    return reasons;
  },
);

export const isBasketClearpayEligible = createSelector(
  [shouldShowClearpayFeature, selectClearpayIneligibilityReasons],
  (shouldShowClearpayFeature, reasons) => ({
    isEligible: shouldShowClearpayFeature && reasons.length === 0,
    reasons,
  }),
);

